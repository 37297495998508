// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-addme-js": () => import("./../src/pages/addme.js" /* webpackChunkName: "component---src-pages-addme-js" */),
  "component---src-pages-create-js": () => import("./../src/pages/create.js" /* webpackChunkName: "component---src-pages-create-js" */),
  "component---src-pages-custom-log-in-js": () => import("./../src/pages/CustomLogIn.js" /* webpackChunkName: "component---src-pages-custom-log-in-js" */),
  "component---src-pages-extension-js": () => import("./../src/pages/extension.js" /* webpackChunkName: "component---src-pages-extension-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-home-js": () => import("./../src/templates/blog-home.js" /* webpackChunkName: "component---src-templates-blog-home-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

